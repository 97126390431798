.header {
  width: auto;
  height: 60px;
  background-color: var(--white-color);
}

.header .logo h1 {
  font-size: 2rem;
  margin: 0px;
  /* padding: 10px 0px; */
}

.logo-style {
  object-fit: contain !important;
  width: 200px;
}

.header .logo h1 a {
  width: 166px;
  height: 42px;
  color: var(--blue-color);
  font-size: 2rem;
  font-weight: 500;
}

.header .logo h1 a:hover {
  color: var(--deep-yellow-color);
  text-decoration: none;
  border: 0px;
}

.header .form {
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
}

.header .navbar * {
  box-shadow: none !important;
}

.header .navbar .label {
  font-size: 1rem;
  padding: 4px 8px;
  margin: 0px 2px;
  border-radius: 15px !important;
}

.header .navbar {
  background: none !important;
  border: 0px !important;
}

.header .navbar i {
  margin-right: 3px;
}
.header .navbar .nav > li > a {
  color: var(--white-color) !important;
  font-size: 1rem !important;
  border-bottom: 0px !important;
  margin-top: 0px !important;
  font-weight: bold;
}

.sente-pay-brand-name {
  width: 166px;
  height: 42px;
  color: var(--blue-color);
  font-size: 2rem;
  font-weight: 500;
  line-height: 2;
}

.header .navbar-nav > .open > a,
.header .navbar-nav > .open > a:hover,
.header .navbar-nav > .open > a:focus {
  background: none !important;
}

.header .navbar-collapse {
  border: 0px !important;
}

.header .navbar-header {
  width: 43px;
  margin: 0 auto;
}

.header .navbar-header button {
  color: var(--white-color) !important;
  padding: 5px 10px;
}

.header .navbar-toggle {
  background-color: #1dc1da !important;
  border: 1px solid #0fa6bc !important;
}

.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background-color: #0fa6bc !important;
  border: 1px solid #0fa6bc !important;
}

.header .dropdown-menu {
  border-radius: 6px !important;
  background: var(--white-color) !important;
}

.header .dropdown-menu li {
  font-size: 1rem;
  padding: 6px 15px;
  /* border-bottom:1px dashed #f3f3f3; */
}

.header .dropdown-menu li a {
  color: #888;
  padding-left: 0px !important;
}

.header .dropdown-menu li:last-child {
  border: 0px;
}

.header .dropdown-menu li a:hover {
  background: none !important;
  color: #666 !important;
  border: 0px !important;
}

.header .dropdown-big {
  min-width: 300px;
}

.header .dropdown-big .dropdown-head {
  padding: 7px 15px;
  background: #fcfcfc;
  border-bottom: 1px solid #ddd;
  margin-top: -5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.header .dropdown-big .dropdown-title {
  font-size: 1rem;
  font-weight: bold;
  color: #999;
}

.header .dropdown-big .dropdown-body {
  background: var(--white-color);
}

.header .dropdown-big .dropdown-foot {
  padding: 7px 15px;
  background: #fcfcfc;
  border-top: 1px solid #ddd;
  margin-bottom: -5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1rem;
}

.header .dropdown-big .dropdown-foot a {
  color: #888;
}

.header .dropdown-big .dropdown-foot a:hover {
  color: #555;
  border-bottom: 0px;
}

.header .navbar-nav .caret {
  border-top-color: var(--white-color) !important;
  border-bottom-color: var(--white-color) !important;
}

.header .navbar-nav > .open > a .caret,
.header .navbar-nav > .open > a:hover .caret,
.header .navbar-nav > .open > a:focus .caret {
  border-top-color: var(--white-color) !important;
  border-bottom-color: var(--white-color) !important;
}

.sente-brand-name {
  font-family: Arial - Extra Bold;
  font-weight: 800;
}

.user-name {
  color: #0e0e0d;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
}
.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.custom-header-link {
  font-family: Arial !important;
  font-size: 1rem;
  font-weight: 500;
}

.avatar-img-container {
  border-radius: 10px;
  background-color: var(--deep-yellow-color) !important;
}
.avatar-img-text {
  color: var(--white-color);
  font-size: 0.65rem;
  padding: 7px 7px 7px 7px;
  text-transform: uppercase;
}

.change-plan-text {
  color: #888;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 7px;
}
.change-plan-text:hover {
  color: var(--deep-yellow-color) !important;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  font-size: 0.875rem;
}

.pointer-cursor {
  cursor: pointer;
}
.header-shadow {
  box-shadow: 0 1px 1px 0 #bab5b5;
}

.active-link {
  color: var(--deep-yellow-color) !important;
  text-decoration: none;
  background-color: transparent;
}

@media (max-width: 767px) {
  .header {
    height: auto;
    padding: 15px 0px;
  }
  .header .logo {
    text-align: center;
    padding-bottom: 10px;
  }
  .header .navbar .nav > li > a:hover {
    background: #0fa6bc;
    border-radius: 5px;
  }
  .navbar-toggle {
    margin-right: 0px !important;
  }
}

@media (max-width: 991px) {
  .header {
    height: auto;
    padding: 15px 0px;
  }
  .header .logo {
    text-align: center;
    padding-bottom: 10px;
  }
  .header .form {
    margin: 10px auto;
  }
  .sidebar,
  content-box {
    margin-bottom: 30px;
    width: 100%;
    float: none;
    position: relative;
  }
  .mainy {
    margin-left: 0px;
  }
}

.custom-div {
  display: flex;
  min-height: 24px;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.custom-div-span {
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  line-height: 1.22;
}

.logout-style {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-nav {
  display: flex;
  flex-direction: row;
}
.custom-nav-div {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 8px;
}
.custom-image-span {
  height: 32px;
  width: 32px;
  -webkit-box-align: stretch;
  align-items: stretch;
  background-color: var(#ffffff);
  border-radius: 50%;
  box-sizing: content-box;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  overflow: hidden;
  position: static;
  transform: translateZ(0px);
  transition: transform 200ms ease 0s, opacity 200ms ease 0s;
  box-shadow: 0 0 0 2px var(#494444);
  border: none;
  margin: 2px;
  padding: 0px;
  font-size: inherit;
  font-family: inherit;
}
.custom-image {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-header {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.45455;
  font-weight: 500;
  color: rgb(107, 119, 140);
}
