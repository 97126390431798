.box {
  box-shadow: 0px 10px 25px -20px hsl(229, 6%, 66%);
  padding: 30px;
  margin: 20px;
  border-radius: 5px;
  border-top: 3px solid #f87f0f;
}

.h2 {
  font-weight: 400;
  font-size: 35px;
}
