.main-footer {
  background-color: var(--light-color);
  -webkit-box-shadow: 1px -1px 2px 0 rgba(160, 153, 153, 0.75);
  -moz-box-shadow: 1px -1px 2px 0 rgba(160, 153, 153, 0.75);
  box-shadow: 1px -1px 2px 0 rgba(160, 153, 153, 0.75);
}
.footer-text {
  width: 116px;
  height: 22px;
  color: #64686d;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}

footer {
  background-color: #2c3742;
  box-shadow: inset 0px 0px 3px #111;
  color: var(--white-color);
  font-size: 0.8rem;
  line-height: 22px;
  padding: 10px 0px 10px 0px;
  bottom: 0px;
}

footer a {
  color: #eee;
  text-decoration: none;
  border-bottom: 1px dotted #888;
}

footer a:hover {
  color: #aaa;
  text-decoration: none;
  border: 0px;
}

footer hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: var(--dark-color) 1px solid;
  border-bottom: #212121 1px solid;
}

#footer-back {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
#auth-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-content: space-between !important;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.footer-cop {
  width: 116px;
  height: 22px;
  color: #64686d;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 2;
}
